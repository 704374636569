var photos = ["#title_photo1", "#title_photo2"];
var upto = 0;
var total = 1;
var way_port = 0;
var way_in_view = 0;
function cross_fade() {
	if (total != upto){
		$(photos[upto]).css("opacity", "0");
		$(photos[upto + 1]).css("opacity", "1");

		upto = upto + 1
	}else{
		$(photos[upto]).css("opacity", "0");
		$(photos[0]).css("opacity", "1");

		upto = 0
	}
}
function in_cross_fade_way(){
	$("#old_way").css("opacity", "0");
	$("#new_way").css("opacity", "1");
}
function out_cross_fade_way(){
	$("#old_way").css("opacity", "1");
	$("#new_way").css("opacity", "0");
}
document.addEventListener("turbolinks:load", function() {
    $(window).bind("scroll", function(event) {
		$('.way_wrap:in-viewport').each(function() {
		  if (way_in_view == 0){
		  	setTimeout(in_cross_fade_way, 5000)
		  	way_in_view = 1;
		  }
		});
		$('.way_wrap:below-the-fold').each(function() {
		  if (way_in_view == 1){
		  	out_cross_fade_way();
		  	way_in_view = 0;
		  }
		});
		$('.way_wrap:above-the-top').each(function() {
		  if (way_in_view == 1){
		  	out_cross_fade_way();
		  	way_in_view = 0;
		  }
		});
	});
});

setInterval(cross_fade, 5000);

(function($){$.belowthefold=function(element,settings){var fold=$(window).height()+$(window).scrollTop();return fold<=$(element).offset().top-settings.threshold;};$.abovethetop=function(element,settings){var top=$(window).scrollTop();return top>=$(element).offset().top+$(element).height()-settings.threshold;};$.rightofscreen=function(element,settings){var fold=$(window).width()+$(window).scrollLeft();return fold<=$(element).offset().left-settings.threshold;};$.leftofscreen=function(element,settings){var left=$(window).scrollLeft();return left>=$(element).offset().left+$(element).width()-settings.threshold;};$.inviewport=function(element,settings){return!$.rightofscreen(element,settings)&&!$.leftofscreen(element,settings)&&!$.belowthefold(element,settings)&&!$.abovethetop(element,settings);};$.extend($.expr[':'],{"below-the-fold":function(a,i,m){return $.belowthefold(a,{threshold:0});},"above-the-top":function(a,i,m){return $.abovethetop(a,{threshold:0});},"left-of-screen":function(a,i,m){return $.leftofscreen(a,{threshold:0});},"right-of-screen":function(a,i,m){return $.rightofscreen(a,{threshold:0});},"in-viewport":function(a,i,m){return $.inviewport(a,{threshold:0});}});})(jQuery);